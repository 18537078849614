// Bootstrap theme (bootstrap should be between these)
.lux {
  @import "~bootswatch/dist/lux/variables";
  @import "~bootstrap/scss/bootstrap";
  @import "~bootswatch/dist/lux/bootswatch";

  body {
    font-size: 0.9375rem;
  }

  /** Lux special - make sure the top header has the same height as the one in Darkly */
  .navbar {
    padding: 1rem 1rem;
  }

  .form-control {
    border: 1px solid #222;
  }

  .cid-s48MCQYojq {
    //noinspection CssUnknownTarget
    background-image: url('/images/home/lux_header.jpg');
  }

  .cid-soU9jtw47v {
    //noinspection CssUnknownTarget
    background-image: url('/images/home/lux_footer.jpg');
  }

  .btn {
    padding: 0.55rem 0.75rem;
  }

  .btn-accent {
    color: #fff;
  }

  .nav-tabs .nav-link.active {
    color: #55595c;
    background-color: #fff;
    border-color: #eceeef #eceeef #fff;
  }

  .nav-item {
    margin-right: unset;
  }

  .navbar-light .navbar-nav .nav-link {
    color: unset;
  }

  .home {
    .cid-s48MCQYojq h1,
    .cid-soU9jtw47v h4 {
      color: white !important;
      text-shadow: 1px 1px black;
    }

    .mbr-overlay {
      opacity: 0.1 !important;
    }


    .mbr-parallax-background {
      color: #fff !important;
      text-shadow: 1px 1px black;
    }
  }

  .card_dungeonroute.dungeon_image {
    /* Fixed issue with a gap in the footer in lux, see #747 */
    .header {
      min-height: 44px;
    }

    h4 a,
    .header,
    .description_row,
    .enemy_forces {
      color: #fff;
    }

    .bg-card-footer {
      background-color: #F7F7F7 !important;
    }

    .menu_actions_btn {
      font-size: 0.9rem;
    }
  }

  /** Size of the stars in the "conclude live session" modal dialog */
  .br-theme-fontawesome-stars .br-widget {
    height: 42px;
  }

  .br-theme-fontawesome-stars .br-widget a {
    font-size: 2.48em;
  }

  .modal .modal-content .close {
    color: black;
  }

  .game_version_header {
    img {
      filter: invert(1);
    }

    a {
      color: var(--theme-text-contrast)
    }

    a.active {
      color: var(--theme-light)
    }

    a:hover {
      text-decoration: none;
      color: var(--theme-text-contrast)
    }
  }
}
