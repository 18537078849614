// Bootstrap theme (bootstrap should be between these)
.superhero {
  @import "~bootswatch/dist/superhero/variables";
  @import "~bootstrap/scss/bootstrap";
  @import "~bootswatch/dist/superhero/bootswatch";

  /** Superhero special - make sure the top header has the same height as the one in Darkly */
  .navbar {
    padding: 1rem 1rem;
  }

  .cid-s48MCQYojq {
    background-image: url('/images/home/superhero_header.jpg');
  }

  .cid-soU9jtw47v {
    background-image: url('/images/home/superhero_footer.jpg');
  }
}
