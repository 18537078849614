// Bootstrap theme (bootstrap should be between these)
.darkly {
  @import "~bootswatch/dist/darkly/variables";
  @import "~bootstrap/scss/bootstrap";
  @import "~bootswatch/dist/darkly/bootswatch";

  body, .form-control, .dropdown-menu, .btn {
    font-size: 0.9375rem;
  }

  .form-control {
    border: 1px solid #222;
  }

  .cid-s48MCQYojq {
    //noinspection CssUnknownTarget
    background-image: url('/images/home/darkly_header.jpg');
  }

  .cid-soU9jtw47v {
    //noinspection CssUnknownTarget
    background-image: url('/images/home/darkly_footer.jpg');
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--theme-darker);
  }

  tbody tr.odd {
    background-color: var(--theme-darker);
  }

  /** Size of the stars in the "conclude live session" modal dialog */
  .br-theme-fontawesome-stars .br-widget {
    height: 42px;
  }

  .br-theme-fontawesome-stars .br-widget a {
    font-size: 2.37em;
  }

  .card_dungeonroute.dungeon_image {
    .menu_actions_btn {
      font-size: 0.9rem;
    }
  }

  .game_version_header {
    a {
      color: var(--theme-light)
    }

    a.active {
      color: var(--theme-text-contrast)
    }

    a:hover {
      text-decoration: none;
      color: var(--theme-text-contrast)
    }
  }

  //.btn-info {
  //  color: black;
  //  background-color: #ffbd0a;
  //  border-color: #dc9e0f;
  //}
  //
  //.text-info {
  //  color: #ffbd0a;
  //}
}
