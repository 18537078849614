// Variables
@import "variables";
@import "theme/darkly/darkly";
@import "theme/superhero/superhero";
@import "theme/lux/lux";

// Bootstrap Select
@import "~bootstrap-select/dist/css/bootstrap-select.min.css";
// Datatables
@import "~datatables/media/css/jquery.dataTables.min.css";
// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
// Leaflet
@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";
@import "~leaflet-contextmenu/dist/leaflet.contextmenu.css";
@import "~leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
// Bar rating
//@import "~jquery-bar-rating/dist/themes/fontawesome-stars.css";
// Light Slider
@import "lightslider/dist/css/lightslider.min.css";
// Intro.js
@import "~intro.js/minified/introjs.min.css";
@import "~intro.js/themes/introjs-dark.css";
// Password strength meter
@import "~password-strength-meter/dist/password.min.css";
// Noty
@import "~noty/src/noty.scss";
//@import "~noty/src/themes/bootstrap-v4.scss";
@import "noty";
// Pickr
@import "~@simonwep/pickr/dist/themes/nano.min.css";
// Grapick
@import "~grapick/dist/grapick.min.css";
// Simplebar
@import "~simplebar/dist/simplebar.min.css";
// Bootstrap4-toggle
@import "~bootstrap4-toggle/css/bootstrap4-toggle.min.css";
// Ion Range Slider
@import "~ion-rangeslider/css/ion.rangeSlider.min.css";
// Fonts
@font-face {
    font-family: Salesforce Sans;
    src: url('/webfonts/Renogare-Regular.otf') format('otf');
}
